import {
    CheckIcon,
    CheckboxIcon,
    DiskIcon,
    DocumentIcon,
    DownloadIcon,
    DuplicateIcon,
    EyeIcon,
    FilterIcon,
    PencilIcon,
    PlusIcon,
    RedoIcon,
    SettingsIcon,
    SignInIcon,
    SignInAltIcon,
    CrossCircleIcon,
    CrossIcon,
    LockIcon,
    MinusIcon,
    TrashIcon,
    EyeCrossedIcon,
    FolderAddIcon,
    ArrowLeftIcon,
    LinkIcon,
    DotsMenuIcon,
    VerticalDotsIcon,
    MenuBurgerIcon,
    RefreshIcon,
    GridIcon,
    BoomIcon,
    PortraitIcon,
    ShopIcon,
    ShoppingCartIcon,
    AngleSmallLeftIcon,
    AngleDoubleSmallLeftIcon,
    AngleDoubleSmallRightIcon,
    AngleSmallRightIcon,
    UndoIcon,
    SquareIcon,
    SearchIcon,
    FolderIcon,
    AddFolderIcon,
    InboxIcon,
    CloudIcon,
    BellIcon,
    ExpandIcon,
    PictureIcon,
    CursorFingerIcon,
    InfoIcon,
    ShieldExclamationIcon,
    ExclamationIcon,
    HelpIcon,
    AddIcon,
    EditIcon,
    PeripheralIcon,
    DashboardIcon,
    ModuleIcon,
    MenuIcon,
    BigBrochureIcon,
    TemplateIcon,
    HeartIconWithBorder,
    HeartIcon,
    MultiEditIcon,
    OppositeArrow,
    CameraIcon,
    StarIcon,
    AllergenIcon,
    ItemIcon,
    StepIcon,
    TagIcon,
    WorkflowIcon,
    CategoryIcon,
} from "./components/UiconsSvgIcons";

export const ICONS: { [key: string]: any } = {
    EditIcon,
    AddIcon,
    CheckIcon,
    CheckboxIcon,
    DiskIcon,
    DocumentIcon,
    DownloadIcon,
    DuplicateIcon,
    EyeIcon,
    FilterIcon,
    PencilIcon,
    PlusIcon,
    RedoIcon,
    SettingsIcon,
    SignInIcon,
    SignInAltIcon,
    CrossCircleIcon,
    CrossIcon,
    LockIcon,
    MinusIcon,
    TrashIcon,
    EyeCrossedIcon,
    FolderAddIcon,
    ArrowLeftIcon,
    LinkIcon,
    DotsMenuIcon,
    VerticalDotsIcon,
    MenuBurgerIcon,
    RefreshIcon,
    GridIcon,
    BoomIcon,
    PortraitIcon,
    ShopIcon,
    ShoppingCartIcon,
    AngleSmallLeftIcon,
    AngleDoubleSmallLeftIcon,
    AngleDoubleSmallRightIcon,
    AngleSmallRightIcon,
    UndoIcon,
    SquareIcon,
    SearchIcon,
    FolderIcon,
    AddFolderIcon,
    HelpIcon,
    InboxIcon,
    CloudIcon,
    BellIcon,
    ExpandIcon,
    PictureIcon,
    CursorFingerIcon,
    InfoIcon,
    ShieldExclamationIcon,
    ExclamationIcon,
    PeripheralIcon,
    DashboardIcon,
    ModuleIcon,
    MenuIcon,
    BigBrochureIcon,
    TemplateIcon,
    HeartIconWithBorder,
    HeartIcon,
    MultiEditIcon,
    CameraIcon,
    StarIcon,
    AllergenIcon,
    ItemIcon,
    StepIcon,
    TagIcon,
    WorkflowIcon,
    CategoryIcon,
    OppositeArrow
};

export const black = "#000000";
export const white = "#FFFFFF";

export const COLORS: { [key: string]: string } = {
    background: "#F8F8FB",
    principal: "#323333",
    secondary: "#626368",
    disabledButtonBorder: "#C6C6C6",
    disabled: "#EFEFEF",
    white: "#FFFFFF",
    text: "#2B2828",
    danger: "#E30613",
    reminder: "#AE0F0A",
    success: "#34C38F",
    info: "#0077D7",
    warning: "#F1B44C",
};
